<template>
	<div id="log_manage">
		<jy-query ref="form" :model="form">
			<jy-query-item label="线路:" prop="routeId" label-width="70px">
				<!-- <div @click="xlselectInstitutions">
                    <el-input placeholder="请选择" v-model="form.routeName"></el-input>
                </div> -->
				<el-select v-model="form.routeId" placeholder="请选择线路">
					<el-option v-for="item in routeOptions" :key="item.routeId" :label="item.name" :value="item.routeId"> </el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item prop="vehicleNo" label="车牌号:">
				<el-input placeholder="请输入" v-model="form.vehicleNo"></el-input>
			</jy-query-item>
			<jy-query-item label="统计日期:" prop="time" :span="1.5">
				<el-date-picker
					v-model="form.time"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					value-format="yyyy-MM-dd"
					:picker-options="pickerOptions"
					:unlink-panels="true"
				></el-date-picker>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck">查询</el-button>
				<el-button type="primary" plain @click="resetForm('form')">重置</el-button>
				<!-- <el-row> -->
				<!-- <el-button type="primary">导出</el-button> -->
				<!-- </el-row> -->
			</template>
		</jy-query>
		<jy-table max-height="595" :data="dataList" :span-method="objectSpanMethod">
			<jy-table-column prop="idx" label="序号" width="60"></jy-table-column>
			<jy-table-column prop="routeName" label="线路"></jy-table-column>
			<jy-table-column prop="orgName" label="所属机构"></jy-table-column>
			<jy-table-column prop="vehicleNo" label="车牌号"></jy-table-column>
			<jy-table-column prop="object" label="项目"></jy-table-column>
			<jy-table-column :prop="i.no" :label="i.date" v-for="i in list" :key="i.no"></jy-table-column>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		></jy-pagination>
		<xlinstitutions-tree
			ref="xlinstitutions"
			@addInstitutions="xladdInstitutions"
			url="/waybillOperate/getTree"
			title="选择线路"
			:defaultProps="props"
		></xlinstitutions-tree>
	</div>
</template>
<script>
import xlinstitutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
export default {
	data() {
		return {
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() > Date.now() - 8.64e7;
				}
			},

			props: {
				children: "children",
				label: "text",
				value: "id"
			},
			form: {
				routeName: "",
				routeId: "",
				vehicleNo: "",
				time: "",
				beginDate: "",
				endDate: ""
			},
			pageSize: 10,
			total: 0,
			pageIndex: 1,
			// 列表列数据
			list: [],

			dataList: [],
			routeOptions: []
		};
	},
	components: {
		xlinstitutionsTree
	},
	created() {
		this.getList();
	},
	activated() {
		this.getRouteList();
	},
	methods: {
		getRouteList() {
			let url = "/baseinforoute/queryCurrentAuthorityRoute";
			this.$http.post(url).then(({ detail }) => {
				this.routeOptions = detail;
			});
		},
		// 合并列表
		objectSpanMethod({ rowIndex, columnIndex }) {
			// console.log(row)
			// console.log(column)
			// console.log(rowIndex)
			// console.log(columnIndex)
			if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 3) {
				if (rowIndex % 5 === 0) {
					return {
						rowspan: 5,
						colspan: 1
					};
				} else {
					return {
						rowspan: 0,
						colspan: 0
					};
				}
			}
		},
		getList() {
			let option = {
				...this.form,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			if (option.time) {
				option.beginDate = option.time[0];
				option.endDate = option.time[1];
			} else {
				option.beginDate = "";
				option.endDate = "";
			}
			let url = "/statvehicleoperatedaily/queryPageList";
			// console.log(option)
			this.$http.post(url, option).then(res => {
				if (res.retain == "1") {
					this.dataList = [];
					this.$message({
						message: res.error,
						type: "warning"
					});
				} else {
					let r = res.detail.list;
					this.list = r[0].projects;
					// 列表数组
					let showData = [];
					r.forEach(item => {
						let a = [
							{
								idx: item.idx,
								routeName: item.routeName,
								vehicleNo: item.vehicleNo,
								object: "驾驶员"
							},
							{
								idx: item.idx,
								routeName: item.routeName,
								vehicleNo: item.vehicleNo,
								object: "里程"
							},
							{
								idx: item.idx,
								routeName: item.routeName,
								vehicleNo: item.vehicleNo,
								object: "班次"
							},
							{
								idx: item.idx,
								routeName: item.routeName,
								vehicleNo: item.vehicleNo,
								object: "营收"
							},
							{
								idx: item.idx,
								routeName: item.routeName,
								vehicleNo: item.vehicleNo,
								object: "备注"
							}
						];
						item.projects.forEach(one => {
							a[0][one.no] = one.driverName;
							a[1][one.no] = one.mileage;
							a[2][one.no] = one.tripNum;
							a[3][one.no] = one.revenue;
							a[4][one.no] = one.remark;
						});
						showData = showData.concat(a);
					});
					console.log(showData);
					this.dataList = showData;
					this.total = res.detail.total;
				}
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getList();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.form.routeId = "";
			this.oncheck();
		},
		// 选择线路树
		xlselectInstitutions() {
			this.$refs.xlinstitutions.init();
		},
		// 上级线路机构查询
		xladdInstitutions(data) {
			// console.log(data)
			this.form.routeId = data.id;
			this.form.routeName = data.text;
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		}
	}
};
</script>
